<template>
  <div class="mx-2 mb-2">
    <div class="row">
      <div class="col-md-12">
        <b-alert show class="mb-2" variant="danger" v-if="hasRelatedJob">
          <i class="fa fa-check-circle mr-2"></i>
          <span v-if="loading" class="sub-title mr-2">Loading Project...</span>
          <span v-if="!loading" class="sub-title mr-2">
            <span v-if="isDisabled">Editing has been disabled.</span>
            <template v-if="project">
              A job
              <a
                @click.prevent="navigateToJob"
                class="edit-link quote-view-job-link-1"
                target="_blank"
                :href="'/projects/details/' + currentDataObj.details.JobID"
                :id="`quote-edit-button-row-id-${currentDataObj.details.JobID}-1`"
                >{{ currentDataObj.details.JobReferenceNumber }}</a
              >
              is already associated with this quote.
              <a
                @click.prevent="navigateToJob"
                class="edit-link quote-view-job-link-2"
                target="_blank"
                :href="'/projects/details/' + currentDataObj.details.JobID"
                :id="`quote-edit-button-row-id-${currentDataObj.details.JobID}-2`"
              >
                Click here to edit the job.</a
              >
            </template>
          </span>
        </b-alert>
      </div>
      <div class="col-lg-6 col-md-12 pr-lg-1 mb-lg-0 mb-2">
        <b-card class="details">
          <div class="header mb-3 d-flex justify-content-between">
            <h5>Client/Prospect Information</h5>
            <i class="fa fa-info-circle info-icon text-secondary" id="basicinfo">
              <b-tooltip target="basicinfo" triggers="hover" placement="righttop">
                <div>Client/Prospect Info</div>
                <p>Choose the client or prospect you would like to send a quote to.</p>
              </b-tooltip>
            </i>
          </div>
          <div class="content">
            <div class="row">
              <div class="col-md-12 mb-2">
                <span class="c-toolbar__state-title u-text-capitalize">Company</span>
                <v-select
                  :disabled="isDisabled"
                  :options="UXData.uxClientProspect"
                  id="slidepanel-edit-quote-company"
                  :value="getSelectedDropDownValue('uxClientProspect', 'IntClientID')"
                  @input="
                    onDropdownSelectionChangeFilter(
                      $event,
                      'Client/Prospect', //Display Name
                      'uxClientProspect', //Dropdown List Object
                      'IntClientID', //Edit Details Property Name
                      false,
                      'uxClientLocation'
                    )
                  "
                  label="displayName"
                  :clearable="false"
                ></v-select>
              </div>
              <div class="col-md-12 mb-2">
                <span class="c-toolbar__state-title u-text-capitalize">Location </span>
                <v-select
                  :disabled="isDisabled"
                  id="slidepanel-edit-quote-location"
                  :options="UXData.uxClientLocation"
                  v-model="selectedLocation"
                  @input="
                    onDropdownSelectionChange(
                      $event,
                      'Location', //Display Name
                      'uxClientLocation', //Dropdown List Object
                      'IntLocationID' //Edit Details Property Name
                    )
                  "
                  label="displayName"
                  :clearable="false"
                ></v-select>
              </div>

              <div class="col-md-6 mb-2">
                <!-- <span class="c-toolbar__state-title u-text-capitalize">Status</span> -->
                <global-settings-modal id="slidepanel-edit-sales-details-status" label="Status" :actionId="MODULE.QUOTATION.UX.QuoteStatus" />
                <v-select
                  :disabled="isDisabled"
                  :options="UXData.uxQuoteStatus"
                  id="slidepanel-edit-quote-status"
                  :value="getSelectedDropDownValue('uxQuoteStatus', 'IntQuoteStatusID')"
                  @input="
                    onDropdownSelectionChange(
                      $event,
                      'Quote Status', //Display Name
                      'uxQuoteStatus', //Dropdown List Object
                      'IntQuoteStatusID' //Edit Details Property Name
                    )
                  "
                  label="displayName"
                ></v-select>
              </div>

              <div class="col-md-6 mb-2">
                <span class="c-toolbar__state-title u-text-capitalize">Template</span>
                <v-select
                  :disabled="isDisabled"
                  :options="UXData.uxQuoteTemplates"
                  id="slidepanel-edit-quote-template"
                  :value="getSelectedDropDownValue('uxQuoteTemplates', 'IntQuoteTemplateID')"
                  @input="
                    onDropdownSelectionChange(
                      $event,
                      'Quote Document Template', //Display Name
                      'uxQuoteTemplates', //Dropdown List Object
                      'IntQuoteTemplateID' //Edit Details Property Name
                    )
                  "
                  label="displayName"
                ></v-select>
              </div>
            </div>
          </div>
        </b-card>
      </div>

      <div class="col-lg-6 col-md-12 pl-lg-1">
        <b-card class="details">
          <div class="header mb-3 d-flex justify-content-between">
            <h5>Quote Information</h5>
            <i class="fa fa-info-circle info-icon text-secondary" id="pcontact">
              <b-tooltip target="pcontact" triggers="hover" placement="righttop">
                <div>Quote Info</div>
                <p>Enter the email subject, the contact person and your estimated close date?</p>
              </b-tooltip>
            </i>
          </div>
          <div class="content">
            <div class="row">
              <div class="col-md-12 mb-2">
                <span class="c-toolbar__state-title u-text-capitalize">Subject</span>
                <input
                  class="form-control"
                  :disabled="isDisabled"
                  type="text"
                  id="slidepanel-edit-quote-subject"
                  v-model="currentDataObj.details.Subject"
                  @keyup="
                    updateField(
                      'Subject', //PropertyName
                      currentDataObj.details.Subject, //Property Value
                      'Subject' //Display Name
                    )
                  "
                />
              </div>

              <div class="col-md-12 mb-2">
                <span class="c-toolbar__state-title u-text-capitalize">Attention To</span>
                <input
                  class="form-control"
                  :disabled="isDisabled"
                  type="text"
                  id="slidepanel-edit-quote-attention-to"
                  v-model="currentDataObj.details.AttentionTo"
                  @keyup="
                    updateField(
                      'AttentionTo', //PropertyName
                      currentDataObj.details.AttentionTo, //Property Value
                      'Attention' //Display Name
                    )
                  "
                />
              </div>

              <div class="col-md-6 mb-2">
                <span class="c-toolbar__state-title u-text-capitalize">Target Close Date</span>
                <date-picker
                  v-model="targetCloseDate"
                  value-type="date"
                  id="slidepanel-edit-quote-target-close-date"
                  :disabled="isDisabled"
                  format="DD-MMM-YYYY"
                  style="width: 100%;"
                  lang="en"
                  @input="
                    updateField(
                      'TargetCloseDate', //PropertyName
                      currentDataObj.details.TargetCloseDate, //Property Value
                      'Target Close Date' //Display Name
                    )
                  "
                ></date-picker>
              </div>

              <div class="col-md-6 mb-2">
                <span class="c-toolbar__state-title u-text-capitalize">Sales Rep.</span>
                <v-select
                  :options="UXData.uxSalesRep"
                  :disabled="isDisabled"
                  id="slidepanel-edit-quote-sales-rep"
                  :value="getSelectedDropDownValue('uxSalesRep', 'IntSalesRepUserID')"
                  @input="
                    onDropdownSelectionChange(
                      $event,
                      'Sales Rep.', //Display Name
                      'uxSalesRep', //Dropdown List Object
                      'IntSalesRepUserID' //Edit Details Property Name
                    )
                  "
                  label="displayName"
                >
                </v-select>
              </div>
            </div>
          </div>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
  import editMixin from "@/mixin/edit-mixin";
  import GlobalServices from "@/services/global";
  import ProjectServices from "@/services/projects";
  import DashboardService from "@/services/dashboard";
  import MODULE from "@/constants/modules";
  let api = new ProjectServices();

  let global = new GlobalServices();

  export default {
    mixins: [editMixin],
    data() {
      return {
        loading: true,
        projectId: 0,
        project: null, //If the quote has a related job, load the details (will need this details to navigate to the job page.)
        selectedLocation: {
          displayName: "Select a Location",
          id: "0",
        },
      };
    },
    computed: {
      hasRelatedJob: function() {
        if (this.currentDataObj.details.JobID) {
          return true;
        } else {
          return false;
        }
      },
      targetCloseDate: {
        // getter
        get: function() {
          return this.currentDataObj.details.TargetCloseDate;
        },
        // setter
        set: function(newLocationValue) {
          this.currentDataObj.details.TargetCloseDate = newLocationValue;
        },
      },
    },
    methods: {
      //Fires via global event bus, to reload the location dropdown on client change triggered by edit-mixin onDropdownSelectionChange function
      async loadDropDownList(clientId) {
        //Call API and get dropdown values
        this.UXData = await this.dataObj.uxdata(this.dataObj.pageName, clientId);

        //Set Location Selected Values
        if (this.UXData.uxClientLocation) {
          //Handle setting value on initial load... if Location Id exist
          if (clientId) {
            this.selectedLocation = this.getSelectedDropDownValue("uxClientLocation", "IntLocationID");

            //Set Location Default
            //TO DO: Revaluate selecting the default option if there are multiple locations....
            //Maybe we need to just display a message... Please Select a Location
            //IF Theres only one result, then set the value automatically
            if (this.selectedLocation) {
              if (this.UXData.uxClientLocation.length == 1) {
                this.selectedLocation = this.UXData.uxClientLocation[0];

                //Update API with the currently selected value
                this.updateField(
                  "IntLocationID", //Edit Details Property Name,
                  this.selectedLocation.id,
                  "Location",
                  this.selectedLocation.displayName,
                  "uxClientLocation" //Dropdown List Object,
                  //previousDisplayValue?,
                  //previousValueId?,
                  //isModal?
                );
              }

              //Assign selected value to the current object, so we can access it else where
              this.currentDataObj.details.selectedLocation = this.selectedLocation;
              this.modalObj.details = this.currentDataObj.details;
              this.$store.dispatch("setModalDataDetailsObject", this.modalObj.details);
            }
          }
        }
      },
      //For projects that have a job already created, load the job details to help with the navigation
      async loadRelatedProject() {
        //If there is a related job/projectID disable editing...
        if (this.currentDataObj.details.JobID) {
          this.isDisabled = this.currentDataObj.details.IsLockedFromEditing !== null ? this.currentDataObj.details.IsLockedFromEditing : true;
        } else {
          this.isDisabled = false;
        }
        if (this.currentDataObj.details.JobID) {
          this.loading = true;
          // this.isDisabled = this.currentDataObj.details.JobID && this.currentDataObj.details.IsLockedFromEditing !== null ? this.currentDataObj.details.IsLockedFromEditing : false;
          this.projectId = this.currentDataObj.details.JobID;
          this.project = await api.getProjectDetails(this.projectId);
          //LookUp Related Project
          let filter = {};

          filter.projectRefNumber = this.projectId;

          const dashboardService = new DashboardService();

          let results = await dashboardService.getProjects(filter);
          if (results.resources.length > 0) {
            this.project = results.resources.find((wo) => wo.intProjectID === this.projectId);
            this.loading = false;
          }
        }
      },

      //Link to open Quote Related job
      async navigateToJob() {
        this.projectId = this.currentDataObj.details.JobID;

        let newPath = "/projects/details/" + this.projectId;
        let route = {name: "projects-details", params: {id: this.projectId}};

        //Update the router
        if (newPath !== this.$route.path) {
          this.$router.push(route);
        }

        this.setPanelProperties(this.project, route);
      },

      setPanelProperties(data, route) {
        let sidePanel = this.$store.getters.getSidePanel;

        sidePanel.isOpen = true;

        sidePanel.data = data;

        sidePanel.panelObj.show();

        sidePanel.panelObj.promise.then((result) => {});

        //Send Data to Side Panel
        this.$store.dispatch("setSidePanel", sidePanel);
      },
    },
    async mounted() {
      //Callback function to get he location list
      let clientId = this.dataObj.details.IntClientID;
      await this.loadDropDownList(clientId);

      await this.loadRelatedProject();

      //Subscribe to client dropdown change event in editMixin
      this.$root.$on(this.onClientDropDownEvent, this.loadDropDownList);
    },

    async created() {
      this.MODULE = MODULE;
    },
    beforeDestroy() {
      //Unscribe from save button event...
      this.$root.$off(this.onClientDropDownEvent);
    },
  };
</script>
