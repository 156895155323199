<template>
  <div>
    <!-- Header Section -->
    <panelheader v-if="sidePanelDataObj.headerDataObj" :header-data-obj="sidePanelDataObj.headerDataObj"></panelheader>

    <info v-if="sidePanelDataObj.info" :data-obj="sidePanelDataObj" class="px-2"></info>

    <!-- Sales Details -->
    <salesdetails v-if="sidePanelDataObj.details" :data-obj="sidePanelDataObj"></salesdetails>

    <!-- Tabs Section -->
    <tabs v-if="sidePanelDataObj.tabSectionDataObj" :data-obj="sidePanelDataObj"
      :tab-data-obj="sidePanelDataObj.tabSectionDataObj" :iFrame="sidePanelDataObj.iFrame"></tabs>


  </div>
</template>

<script>
//Standard Components
import EVENTS from "@/constants/events";


//Template Layout Components
import panelheader from "@/components/_universal/sidepanel/sidepanel-header";
import actionbar from "@/components/_universal/sidepanel/sidepanel-action-bar";
import panelsummary from "@/components/_universal/sidepanel/sidepanel-summary-info";
import tabs from "@/components/_universal/sidepanel/sidepanel-tabs";
//Project Earth

import sidePanelIFrame from "@/components/_universal/sidepanel/sidepanel-iframe";

import info from "@/components/_universal/details-info.vue";

import salesdetails from "@/components/sales/sales-details.vue";

//Controller
import SalesController from "@/controllers/sales-controller";

//Initialize Controller and pass data to be loaded/displayed
const salesController = new SalesController();


export default {
  data() {
    return {
      sidePanelDataObj: {},
      EVENT_ACTION: {},
      iframeUrl: null
    };
  },
  methods: {
    async loadData() {
      let sidePanel = this.$store.getters.getSidePanel;


      //Ensure the side bar is open
      if (sidePanel.isOpen === true) {

        //Load data from store, for side panel
        //Set Header Properties to be passed in as prop values
        this.sidePanelDataObj = await salesController.main(sidePanel.data);


        //Check if Side Panel should load Iframe
        if (this.sidePanelDataObj.isSidePanelFrame) {
          this.iframeUrl = this.sidePanelDataObj.url;
        }
      }
    }
  },
  components: {
    panelheader,
    salesdetails,
    info,
    tabs
  },
  async mounted() {
    //ONLY TRACK EVENTS IN PRODUCTION
    // if (process.env.VUE_APP_ENVIRONMENT === "production") {
    //   window["analytics"].page(this.EVENT_ACTION.QUOTATION.View);
    // }
  },
  async created() {
    //Import event constants and set local variable
    this.EVENT_ACTION = EVENTS;

    await this.loadData();
  }
};
</script>

